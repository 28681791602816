/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable new-cap */
/* eslint-disable no-param-reassign */
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { isWeekend, eachDayOfInterval } from 'date-fns';
import Logo from '../../../../../../shared/img/logo/logo-jaf.png';

import { Robfont } from '../../Roboto-normal';
import { RobBoldfont } from '../../Roboto-bold';

const PDFGeneratorValor = (ano, mes, data, horasPDF, obras, dataInicio, dataFim, diasMes) => {
  let mesAnterior = mes - 1;
  const totalPagesExp = '##';
  if (mesAnterior <= 0) {
    mesAnterior = 12;
  }

  let y = [];
  // const filterHoras = horasPDF.filter((hora) => hora.total !== '0.00 €');
  y.push(horasPDF);

  y = y.flat();

  // Default export is a4 paper, portrait, using millimeters for units
  // const zip = new JSZip();
  const doc = new jsPDF('landscape', 'mm', 'a4', true);
  const registosPorPagina = 15;
  let pageCount = Math.round(y.length / registosPorPagina);
  if (pageCount === 0) {
    pageCount = 1;
  }

  doc.setFont('Roboto-Regular');

  const headers = [
    {
      dataKey: 'recursos_nome2',
      header: 'Nome',
      halign: 'left',
    },
  ];

  headers.push({
    dataKey: 'valorPorHora',
    header: 'Valor',
  });

  headers.push(
    {
      dataKey: 'd1',
      header: '1',
      width: 100,
    },
    {
      dataKey: 'd2',
      header: '2',
    },
    {
      dataKey: 'd3',
      header: '3',
    },
    {
      dataKey: 'd4',
      header: '4',
    },
    {
      dataKey: 'd5',
      header: '5',
    },
    {
      dataKey: 'd6',
      header: '6',
    },
    {
      dataKey: 'd7',
      header: '7',
    },
    {
      dataKey: 'd8',
      header: '8',
    },
    {
      dataKey: 'd9',
      header: '9',
    },
    {
      dataKey: 'd10',
      header: '10',
    },
    {
      dataKey: 'd11',
      header: '11',
    },
    {
      dataKey: 'd12',
      header: '12',
    },
    {
      dataKey: 'd13',
      header: '13',
    },
    {
      dataKey: 'd14',
      header: '14',
    },
    {
      dataKey: 'd15',
      header: '15',
    },
    {
      dataKey: 'd16',
      header: '16',
    },
    {
      dataKey: 'd17',
      header: '17',
    },
    {
      dataKey: 'd18',
      header: '18',
    },
    {
      dataKey: 'd19',
      header: '19',
    },
    {
      dataKey: 'd20',
      header: '20',
    },
    {
      dataKey: 'd21',
      header: '21',
    },
    {
      dataKey: 'd22',
      header: '22',
    },
    {
      dataKey: 'd23',
      header: '23',
    },
    {
      dataKey: 'd24',
      header: '24',
    },
    {
      dataKey: 'd25',
      header: '25',
    },
    {
      dataKey: 'd26',
      header: '26',
    },
    {
      dataKey: 'd27',
      header: '27',
    },
    {
      dataKey: 'd28',
      header: '28',
    },
  );

  if (mes === 2) {
    if (ano % 400 === 0 || (ano % 100 !== 0 && ano % 4 === 0)) {
      headers.push({
        dataKey: 'd29',
        header: '29',
      });
    }
  } else {
    headers.push(
      {
        dataKey: 'd29',
        header: '29',
      },
      {
        dataKey: 'd30',
        header: '30',
      },
    );

    if ([1, 3, 5, 7, 8, 10, 12].indexOf(mes) >= 0) {
      headers.push({
        dataKey: 'd31',
        header: '31',
      });
    }
  }

  headers.push({
    dataKey: 'totalHoras',
    header: 'T.H.',
    width: 50,
  });

  headers.push({
    dataKey: 'total',
    header: 'Total',
  });

  const headerContent = () => {
    doc.addImage(Logo, 'PNG', 0, 0, 42, 25);

    doc.setDrawColor(0);
    doc.setFillColor(235, 235, 235);

    // Titulo do documento
    doc.setFontSize(9);
    // doc.setLineWidth(0.02);
    // doc.rect(230, 8, 60, 13, 'FD');
    // doc.text('Mapa de Horas - Mapa Geral', 148.5, 15, { align: 'center' });
    let text = 'Mapa de Horas';
    doc.setFont('Roboto-Bold', 'normal');
    text += ' | Geral';
    doc.setFont('Roboto-Regular', 'normal');

    const textWidth =
      (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) / doc.internal.scaleFactor;

    const x = (doc.internal.pageSize.width - textWidth) / 2;

    // doc.text('Mapa de Horas ', 140, 25, { align: 'center' }).setFont('Roboto-Bold', 'normal');
    // doc.text(' | Geral', 155.5, 25, { align: 'center' }).setFont('Roboto-Regular', 'normal');
    doc.text(text, x, 25);
    doc.setFontSize(8);
    doc.text(222, 13, `Ano:`).setFont('Roboto-Bold', 'normal');
    doc.text(228.5, 13, `${ano}`).setFont('Roboto-Regular', 'normal');
    doc.text(240, 13, `Mês:`).setFont('Roboto-Bold', 'normal');
    doc.text(247, 13, `${mes}`).setFont('Roboto-Regular', 'normal');
    doc.text(252, 13, `Data de Impressão:`).setFont('Roboto-Bold', 'normal');
    doc.text(277, 13, `${data}`).setFont('Roboto-Regular', 'normal');
  };

  let columnStylesObj = {};
  let numColumn = 0;

  // Coluna: Nome
  if (diasMes === 31) {
    columnStylesObj = {
      ...columnStylesObj,
      [numColumn]: { halign: '', cellWidth: 20, valign: 'middle' },
    };
  } else if (diasMes === 30) {
    columnStylesObj = {
      ...columnStylesObj,
      [numColumn]: { halign: '', cellWidth: 27, valign: 'middle' },
    };
  } else if (diasMes === 29) {
    columnStylesObj = {
      ...columnStylesObj,
      [numColumn]: { halign: '', cellWidth: 34, valign: 'middle' },
    };
  } else if (diasMes === 28) {
    columnStylesObj = {
      ...columnStylesObj,
      [numColumn]: { halign: '', cellWidth: 41, valign: 'middle' },
    };
  }

  numColumn += 1;
  // Coluna: VAlor Hora
  columnStylesObj = {
    ...columnStylesObj,
    [numColumn]: { halign: 'right', valign: 'middle', cellWidth: 13 },
  };
  numColumn += 1;

  for (let index = 0; index < diasMes; index++) {
    columnStylesObj = {
      ...columnStylesObj,
      [numColumn]: { halign: 'center', cellWidth: 7.5, valign: 'middle' },
    };
    numColumn += 1;
  }

  // Coluna: Total Horas
  columnStylesObj = {
    ...columnStylesObj,
    [numColumn]: { halign: 'right', cellWidth: 8, valign: 'middle' },
  };
  numColumn += 1;
  // Coluna: Total
  columnStylesObj = {
    ...columnStylesObj,
    [numColumn]: { halign: 'right', cellWidth: 14, valign: 'middle' },
  };
  numColumn += 1;
  console.log(horasPDF);
  const connectedItems = obras.filter((obra) => horasPDF.some((hora) => hora.obra === obra.id));
  const result = connectedItems.map((obra) => {
    const filteredHoras = horasPDF.filter((hora) => hora.obra === obra.id);
    return { ...obra, horas: filteredHoras };
  });

  const unconnectedItems = horasPDF.filter((hora) => !result.some((obra) => obra.id === hora.obra));
  if (unconnectedItems.length > 0) {
    const unconnectedObject = {
      id: '',
      descricao: 'Sem obra',
      horas: unconnectedItems,
    };
    result.push(unconnectedObject);
  }

  // loop through the main array and create a table for each sub-array
  result.forEach((tableData, index) => {
    // add a new page for each table except the first one
    if (tableData.horas.length !== 0) {
      if (index > 0) {
        doc.addPage();
      }
      doc.setFontSize(10);
      const text = `${tableData.id} - ${tableData.descricao}`;
      const textWidth =
        (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) / doc.internal.scaleFactor;

      const x = (doc.internal.pageSize.width - textWidth) / 2;

      doc.setFont('Roboto-Bold', 'normal');

      doc.text(text, x, 35).setFont('Roboto-Regular', 'normal');

      doc.autoTable({
        columns: headers,
        body: tableData.horas,
        startX: -10,
        startY: 40,
        theme: 'grid',
        margin: { left: 5, right: 5, top: 15 },
        // padding: { top: 0, right: 0, bottom: 0, left: 0 }
        // bodyStyles: { lineColor: [0, 0, 0] },

        columnStyles: columnStylesObj,

        didParseCell(dataParseCell) {
          const rows = dataParseCell.table.body;
          const rowIndex = dataParseCell.row.index;
          const cellCor = `d${parseInt(dataParseCell.column.raw.header, 10)}cor`;
          const cor = tableData.horas[rowIndex][cellCor];
          const horasvalue = `d${parseInt(dataParseCell.column.raw.header, 10)}`;
          const validado = `d${parseInt(dataParseCell.column.raw.header, 10)}validadoAD`;
          const validadoCor =
            !horasPDF[rowIndex][validado] && horasPDF[rowIndex][horasvalue] > 0
              ? [255, 0, 0]
              : [0, 0, 0];

          if (dataParseCell.row.index === rows.length) {
            if (!dataParseCell.cell.raw) {
              dataParseCell.cell.styles.lineWidth = 0;
              dataParseCell.cell.styles.fillColor = null;
            }
          }

          // HEADER - DIAS
          if (
            (dataParseCell.row.section === 'head' || dataParseCell.row.section === 'foot') &&
            !['recursos_nome2', 'valorPorHora', 'totalHoras', 'total'].includes(
              dataParseCell.column.dataKey,
            )
          ) {
            doc.setFont('Roboto-Bold', 'normal');
            const anoActual = ano;
            const mesActual = mes - 1;
            dataParseCell.cell.styles.fillColor = [240, 240, 240];
            dataParseCell.cell.styles.halign = 'center';
            // dataParseCell.cell.styles.valign = 'middle';

            if (
              isWeekend(
                new Date(anoActual, mesActual, parseInt(dataParseCell.column.raw.header, 10)),
              )
            ) {
              // dataParseCell.cell.styles.fillColor = [40, 170, 100];
              dataParseCell.cell.styles.fillColor = 'lightgreen';
              // doc.line(data.cell.x, data.cell.y + data.row.height, data.cell.x, data.cell.y);
            }
            // HEADER - NOME,VALOR,TOTAL HORAS E TOTAL
            else {
              dataParseCell.cell.styles.fillColor = [230, 230, 230];
            }
          }

          // BODY - DIAS
          else if (
            !['recursos_nome2', 'valorPorHora', 'totalHoras', 'total'].includes(
              dataParseCell.column.dataKey,
            )
          ) {
            const anoActual = ano;
            const mesActual = mes - 1;
            dataParseCell.cell.styles.valign = 'middle';

            if (
              isWeekend(
                new Date(anoActual, mesActual, parseInt(dataParseCell.column.raw.header, 10)),
              )
            ) {
              if (parseInt(dataParseCell.cell.text, 10) <= 0) {
                dataParseCell.cell.text = '';
              }
              const cellIndex = dataParseCell.cell.index;
              const weekendDay = parseInt(dataParseCell.column.raw.header, 10);
              const cellAusencia = `d${parseInt(dataParseCell.column.raw.header, 10)}ausencia`;
              const cellHoras = `d${parseInt(dataParseCell.column.raw.header, 10)}`;
              const ausencia = tableData.horas[rowIndex][cellAusencia];
              const valHoras = tableData.horas[rowIndex][cellHoras];

              if (valHoras > 0 && ausencia == null) {
                dataParseCell.cell.text = `${valHoras}`;
                dataParseCell.cell.styles.fillColor = cor;
                dataParseCell.cell.styles.textColor = validadoCor;
              }
              // Horas com faltas
              if (valHoras > 0 && ausencia != null) {
                dataParseCell.cell.text = `${valHoras}  ${ausencia}`;
                dataParseCell.cell.styles.fillColor = cor;
                dataParseCell.cell.styles.textColor = validadoCor;
              }
              // Sem Horas e sem faltas
              if (valHoras <= 0 && ausencia == null) {
                dataParseCell.cell.text = ' - ';
                dataParseCell.cell.styles.fillColor = cor;
                dataParseCell.cell.styles.textColor = validadoCor;
              }
              // Sem Horas e com faltas
              if (valHoras <= 0 && ausencia != null) {
                dataParseCell.cell.text = ausencia;
                dataParseCell.cell.styles.fillColor = cor;
                dataParseCell.cell.styles.textColor = validadoCor;
              }
              if (dataParseCell.row.index === rows.length) {
                dataParseCell.cell.text = ' ';
                dataParseCell.cell.styles.fillColor = cor;
                dataParseCell.cell.styles.textColor = validadoCor;
              }

              // if (valHoras > 0) {
              //   dataParseCell.cell.text = valHoras;
              //   dataParseCell.cell.styles.fillColor = cor;
              // } else if (ausencia) {
              //   dataParseCell.cell.text = ausencia;
              //   dataParseCell.cell.styles.fillColor = cor;
              // } else if (dataParseCell.row.index === rows.length - 1) {
              //   dataParseCell.cell.text = ' ';
              //   dataParseCell.cell.styles.fillColor = cor;
              // } else {
              //   dataParseCell.cell.text = ' - ';
              //   // dataParseCell.cell.styles.fillColor = cor;
              // }
            } else {
              const cellAusencia = `d${parseInt(dataParseCell.column.raw.header, 10)}ausencia`;
              const cellHoras = `d${parseInt(dataParseCell.column.raw.header, 10)}`;
              const ausencia = tableData.horas[rowIndex][cellAusencia];
              const valHoras = tableData.horas[rowIndex][cellHoras];

              // Horas sem faltas
              if (valHoras > 0 && ausencia == null) {
                dataParseCell.cell.text = `${valHoras}`;
                dataParseCell.cell.styles.fillColor = cor;
                dataParseCell.cell.styles.textColor = validadoCor;
              }
              // Horas com faltas
              if (valHoras > 0 && ausencia != null) {
                dataParseCell.cell.text = `${valHoras}  ${ausencia}`;
                dataParseCell.cell.styles.fillColor = cor;
                dataParseCell.cell.styles.textColor = validadoCor;
              }
              // Sem Horas e sem faltas
              if (valHoras <= 0 && ausencia == null) {
                dataParseCell.cell.styles.fillColor = cor;
                dataParseCell.cell.styles.textColor = validadoCor;
              }
              // Sem Horas e com faltas
              if (valHoras <= 0 && ausencia != null) {
                dataParseCell.cell.text = ausencia;
                dataParseCell.cell.styles.fillColor = cor;
                dataParseCell.cell.styles.textColor = validadoCor;
              }

              // if (ausencia != null) {
              //   dataParseCell.cell.text = ausencia;
              //   dataParseCell.cell.styles.fillColor = cor;
              // } else if (!valHoras || valHoras != 0) {
              //   dataParseCell.cell.styles.fillColor = cor;
              // }
            }
          } else if (['recursos_nome2'].includes(dataParseCell.column.dataKey)) {
            dataParseCell.cell.styles.halign = 'left';
            if (dataParseCell.row.section === 'head') {
              dataParseCell.cell.styles.fillColor = [230, 230, 230];
            } else {
              dataParseCell.cell.styles.fillColor = cor;
            }
          } else if (['valorPorHora'].includes(dataParseCell.column.dataKey)) {
            if (dataParseCell.row.section === 'head') {
              dataParseCell.cell.styles.fillColor = [230, 230, 230];
            } else {
              dataParseCell.cell.styles.fillColor = cor;
            }
          }
        },
        didDrawPage: (dataPage) => {
          if (dataPage.pageNumber == 1) {
            headerContent();
          }
          let footerStr = `Página ${doc.internal.getNumberOfPages()}`;
          const totalPag = doc.internal.getNumberOfPages();
          if (typeof doc.putTotalPages === 'function') {
            footerStr = `Página ${totalPag} de ${totalPagesExp}`;
          }
          doc.setFontSize(8);
          // doc.text(footerStr, dataPage.settings.margin.left, doc.internal.pageSize.height - 10);

          doc.text(footerStr, 292, doc.internal.pageSize.height - 10, {
            align: 'right',
          });
          // doc.text('*Registo de ausência', 32, doc.internal.pageSize.height - 10, {
          //   align: 'right',
          // });
        },
        headStyles: {
          fillColor: [230, 230, 230],
          lineWidth: 0.15,
          // lineColor: [255, 255, 255],
          textColor: [80, 80, 80],
          font: 'Roboto-Bold',
          fontSize: 7,
        },
        styles: {
          fontSize: 6,
          margins: 0,
          // padding: 1,
          printHeaders: true,
          lineWidth: 0.15,
          font: 'Roboto-Regular',
          valign: 'middle',
          halign: 'center',
          // cellWidth: 9,
          lineColor: [200, 200, 200],
        },
      });
    }
  });

  let { finalY } = doc.lastAutoTable;

  // ------------------ Footer----------------//
  const numLinhas = Math.floor(obras.length / 3) + 1;

  const fimTabela = 210 - finalY;

  let tamanhoLegenda = 10;
  tamanhoLegenda += 5; // Tamanho de letra do titulo
  tamanhoLegenda += 5; // Espacamento
  tamanhoLegenda += numLinhas * 5;

  if (fimTabela < tamanhoLegenda) {
    // Nova Página
    // doc.addPage('A4', 'l');
    // headerContent();
    // finalY = 35;
    // let footerStr = `Página ${doc.internal.getNumberOfPages()}`;
    // if (typeof doc.putTotalPages === 'function') {
    //   footerStr = `${footerStr} de ${totalPagesExp}`;
    // }
    // doc.setFontSize(9);
    // doc.text(footerStr, 4, doc.internal.pageSize.height - 10);
  } else {
    finalY += 10;
  }

  const containedCol = 287 / 3;
  // let startx = 5;

  // doc.setDrawColor(0);
  // doc.setFillColor(0, 0, 0);
  // doc.setFontSize(9);
  // doc.setFont('Roboto-Bold', 'normal');
  // doc.text('Legenda de obras', 5.1, finalY);
  // finalY += 5;

  // doc.setFont('Roboto-Regular', 'normal');
  // doc.setFontSize(8);
  // obras.map((o) => {
  //   doc.setDrawColor(0);
  //   doc.setFillColor(o.cor || '#fff');
  //   doc.setLineWidth(0.1);
  //   doc.rect(startx, finalY, 3, 3, 'FD');
  //   doc.setDrawColor(0);
  //   doc.setFillColor(0, 0, 0);
  //   doc.setFontSize(8);
  //   doc.text(`${o.id} - ${o.descricao}`, startx + 5, finalY + 2.5, { maxWidth: containedCol - 10 });

  //   startx += containedCol;

  //   if (startx / containedCol > 3) {
  //     startx = 5;
  //     finalY += 8;
  //   }

  //   if (finalY > 190) {
  //     // Nova Página
  //     doc.addPage('A4', 'l');
  //     headerContent();
  //     finalY = 35;
  //     let footerStr = `Página ${doc.internal.getNumberOfPages()}`;
  //     if (typeof doc.putTotalPages === 'function') {
  //       footerStr = `${footerStr} de ${totalPagesExp}`;
  //     }
  //     doc.setFontSize(9);
  //     doc.text(footerStr, 4, doc.internal.pageSize.height - 10);
  //   }

  //   return o;
  // });

  if (typeof doc.putTotalPages === 'function') {
    doc.putTotalPages(totalPagesExp);
  }

  doc.save('Mapa_de_Horas.pdf');
  // doc.save(`Mapa_de_Horas(${data}).pdf`);
  doc.autoPrint();
};

export default PDFGeneratorValor;
